import * as React from "react";
import { Link } from "gatsby";

import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import { InhaltElem } from "../components/Text/SmallerInhalt";

const NotFoundPage = () => (
  <DefaultLayout>
    <Helmet>
      <title>404 - Page not found - STEP</title>
    </Helmet>
    <Page>
      <Container>
        <ContentWrapper>
          <br />
          <br />
          <br />
          <InhaltElem>
            <h1>404: Page not found.</h1>
            <br />
            <p>
              You've hit the void. <Link to="/">Go back.</Link>
            </p>
          </InhaltElem>
        </ContentWrapper>
      </Container>
    </Page>
  </DefaultLayout>
);

export default NotFoundPage;
